*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 15px;
}

body {
  margin: 0;
  --color-text: #fff;
  --color-bg: #000;
  --color-link: #fff;
  --color-link-hover: #fff;
  color: var(--color-text);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: mr-eaves-xl-modern, sans-serif;
}

/* START react-three-fiber styling */

#animation {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
}

/* END react-three-fiber styling */

a {
  text-decoration: underline;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
  text-decoration: none;
}

.frame {
  padding: 3rem 5vw;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
  margin-right: 1rem;
}

.frame__demos {
  margin: 1rem 0;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 13rem);
  position: relative;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
}

.content__title {
  font-family: house-of-cards, serif;
  font-weight: 700;
  font-size: 8vw;
  margin: 0;
  line-height: 1;
}

@media screen and (min-width: 53em) {
  .frame {
    position: fixed;
    text-align: left;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 2rem;
    pointer-events: none;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: auto auto auto;
  }
  .frame__title {
    grid-area: title;
    margin: 0;
  }
  .frame__demos {
    margin: 0;
    grid-area: demos;
    justify-self: center;
  }
  .frame__links {
    grid-area: links;
    padding: 0;
    justify-self: center;
  }
  .frame__author {
    grid-area: author;
    justify-self: end;
  }
  .frame a {
    pointer-events: auto;
  }
  .content {
    height: 100vh;
    justify-content: center;
  }
}
